/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Praktika';
    src: url("../src/assets/fonts/Praktika_Bold.otf") format("opentype");
}

.container-fluid.router-outlet-container {
    width: 90%;
    margin-top: 1%;
    color: #555;
}
label {
    font-weight: 700;
}
#userpermission .multiselect-dropdown .dropdown-list{
    display: block !important;
    padding-top: 0px !important;
    margin-top: 0px !important;
    position: unset !important;
    
}
#userpermission .multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret{
    display: none !important;
}
.btn-primary{
    background-color: #337ab7;
    border-color: #2e6da4;
}
.btn-primary[disabled]{
    cursor: not-allowed;
    opacity: .65;
    background-color: #337ab7;
    border-color: #2e6da4;
}

table.user-view, table.device-view, table.user-devices-list , table.user-activity{
    border: 1px solid #dee2e6;
}

table.user-view td , table.device-view td{
    padding: 0.50rem;
}

table.user-view td b , table.device-view td b{
    color: #555;
}

#systeminfotab .nav-tabs .nav-link.active {
    border-left-color: transparent;
    border-right-color: transparent;
    border-top-color: transparent;
    border-bottom: 2px solid #428bca;
    color: #000000;
}

#systeminfotab .nav-tabs{
    border-bottom: 0px;
    border-bottom: 0;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
}

#systeminfotab .nav-tabs .nav-link {
    color: #555;
}

#systeminfotab .nav-tabs .nav-link:hover {
    text-decoration: none;
    background-color: #eee;
}

#systeminfotab h3
{
    margin: 10px 0px 0px 0px;
    padding: 10px 8px;
    background-color: #428bca;
    color: white;
    font-weight: 400;
}

.table-border {
    border: 1px solid #ddd9d9;
}

.schedule-modal {
    max-height: 54rem;
    overflow-y: auto;

    .modal-header {
      background-color: #5b9bd5;
      color: white;
    }
    
    .modal-close-button {
      color: white;
      opacity: 1;
    }
  
    .runtime-style {
      display: flex;
    }
  
    .runtime-alignment, .runtime-alignment-right {
      padding: 0.5rem;
      flex-grow: 1;
      color: black;
    }
  
    .days-frequency {
      border: 1px solid #ccc;
      padding:0.5em;
      border-radius: 4px
    }
  }